<template>
  <el-card shadow="never">
    <div slot="header" class="clearfix">
      <el-button type="success" @click="addArticle()" style="background: #1d90ff; border:none">添加中类别</el-button>
    </div>
    <el-table
        stripe
        :data="tableData"
        style="width: 100%; margin-top:10px"
        border
    >
      <el-table-column
          prop="id"
          label="类别"
          width="150"
          align="center">
      </el-table-column>
      <el-table-column
          prop="name"
          label="类别名称"
          width="150"
          align="center">
      </el-table-column>
      <el-table-column
          prop="parentId"
          label="父类别编号"
          width="150"
          align="center">

      </el-table-column>
      <el-table-column
          prop="parentName"
          label="父类别名称"
          width="150"
          align="center">
      </el-table-column>

      <el-table-column
          prop="createdTime"
          label="创建时间"
          align="center">
      </el-table-column>
      <el-table-column
          prop="updatedTime"
          label="更新时间"
          align="center">
      </el-table-column>
      <el-table-column
          v-if="userType==-99"
          fixed="right"
          label="操作"
          width="120">
        <template slot-scope="scope">
          <el-button type="success"  style="background: #1d90ff; border:none" size="mini" @click="editArticle(scope.row.id)">编辑
          </el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <div style="text-align: center;margin-top: 20px">
      <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage4"
          :page-sizes="[10, 20, 50, 60]"
          :page-size="100"
          layout="total, sizes, prev, pager, next, jumper"
          :total=pagetotal>
      </el-pagination>
    </div>
  </el-card>
</template>
<style>
.el-table .warning-row {
  background: oldlace;
}

.el-table .success-row {
  background: #f0f9eb;
}
</style>
<script>
import $ from 'jquery';
import Cookies from "js-cookie";

export default {
  methods: {
    getUserInfoByUserName() {
       const username = Cookies.get("username");
      this.$api.get(
          "/user/getUserInfoByUserName",
          null,
          {
            username: username
          },
          (successRes) => {
           
            this.userType = successRes.data.userType;
             console.log(this.userInfo);
          },
          (failureRes) => {
            console.log(failureRes);
          }
      );
    },
    getUserList() {
      this.$api.get(
          "/type/mid/list",
          null,
          {
            name: this.asearch1,
            status: this.asearch2,
          },
          (successRes) => {
             var exf = successRes.data.rows;
            for (var i = 0; i< exf.length;i++){
              exf[i].updatedTime = exf[i].updatedTime.substr(0, 19);
            exf[i].updatedTime = new Date(+new Date(exf[i].updatedTime) + 16 * 3600 * 1000).toISOString().replace(/T/g, ' ').replace(/\.[\d]{3}Z/, '') ;
              exf[i].updatedTime = exf[i].updatedTime.substring(0,10);
               exf[i].createdTime = exf[i].createdTime.substr(0, 19);
            exf[i].createdTime = new Date(+new Date(exf[i].createdTime) + 16 * 3600 * 1000).toISOString().replace(/T/g, ' ').replace(/\.[\d]{3}Z/, '') ;
              exf[i].createdTime = exf[i].createdTime.substring(0,10);
            }
            this.tableData = exf;
            this.pagetotal =  successRes.data.total;
          },
          (failureRes) => {
            console.log(failureRes);
          }
      );
    },
    handleClick(row) {
      console.log(row);
    },
         handleSizeChange(val) {
       this.spagetotal=val;
         this.$api.get(
          "/type/mid/list",
          null,
          {
            page:this.nowpagetotal,
          record:this.spagetotal
          },
          (successRes) => {
            this.pagetotal =  successRes.data.total;
             var exf = successRes.data.rows;
            for (var i = 0; i< exf.length;i++){
              exf[i].updatedTime = exf[i].updatedTime.substr(0, 19);
            exf[i].updatedTime = new Date(+new Date(exf[i].updatedTime) + 16 * 3600 * 1000).toISOString().replace(/T/g, ' ').replace(/\.[\d]{3}Z/, '') ;
              exf[i].updatedTime = exf[i].updatedTime.substring(0,10);
               exf[i].createdTime = exf[i].createdTime.substr(0, 19);
            exf[i].createdTime = new Date(+new Date(exf[i].createdTime) + 16 * 3600 * 1000).toISOString().replace(/T/g, ' ').replace(/\.[\d]{3}Z/, '') ;
              exf[i].createdTime = exf[i].createdTime.substring(0,10);
            }
            this.tableData = exf;

          },
          (failureRes) => {
            console.log(failureRes);
          }
      );

        console.log(val);
      },
      handleCurrentChange(val) {
        this.nowpagetotal=val;
        this.$api.get(
          "/type/mid/list",
          null,
          {
            page:this.nowpagetotal,
          record:this.spagetotal
          },
          (successRes) => {
            this.pagetotal =  successRes.data.total;
             var exf = successRes.data.rows;
            for (var i = 0; i< exf.length;i++){
              exf[i].updatedTime = exf[i].updatedTime.substr(0, 19);
            exf[i].updatedTime = new Date(+new Date(exf[i].updatedTime) + 16 * 3600 * 1000).toISOString().replace(/T/g, ' ').replace(/\.[\d]{3}Z/, '') ;
              exf[i].updatedTime = exf[i].updatedTime.substring(0,10);
               exf[i].createdTime = exf[i].createdTime.substr(0, 19);
            exf[i].createdTime = new Date(+new Date(exf[i].createdTime) + 16 * 3600 * 1000).toISOString().replace(/T/g, ' ').replace(/\.[\d]{3}Z/, '') ;
              exf[i].createdTime = exf[i].createdTime.substring(0,10);
            }
            this.tableData = exf;

          },
          (failureRes) => {
            console.log(failureRes);
          }
      );
      },
    editArticle(id) {
      this.$router.push({ path: 'mmasteredit',query: {id: id}});

    },
    addArticle() {
      this.$router.push({name: 'mmasterAdd', params: {userId: 123}});
    }
  },
  data() {
    return {
      tableData: [],
      asearchdata: {},
      pagetotal:0,
      spagetotal:'10',
      nowpagetotal:'1',
      currentPage4: 1,
      userType:0
    }
  },
  mounted() {
    this.getUserList();
    this.getUserInfoByUserName();
  },
}
</script>